.text a {
    color: var(--highlight);
    text-decoration: none;
}
.text a:hover {
    text-decoration: underline;
}

.text h1 {
    margin: 0;
}
.text h2 {
    margin: 0;
}
.text h3 {
    margin: 0;
}
.text h4 {
    margin: 0;
}
.text h5 {
    margin: 0;
}
.text h6 {
    margin: 0;
}

.text p {
    margin: 0;
    margin-bottom: 4px;
}

.text p:last-child {
    margin-bottom: 0;
}

.text pre {
    margin: 0;
}

.text li {
    margin-top: -1em;
}
.text li:last-child {
    margin-bottom: -2em;
}

.text hr {
    border: 0;
    height: 1px;
    background-image: var(--gray-gradient);
    margin: 20px;
}

.text img, .text video, .text iframe, .text audio {
    max-width: 100%;
    max-height: 500px;
    margin: 10px auto;
    display: block;
    border-radius: 12px;
}

.text iframe, .text video {
    width: -webkit-fill-available;
    aspect-ratio: 16 / 9;
}

.link-metadata {
    cursor: default;
    margin: 10px 0px;
}

.link-metadata .link-title {
    font-size: 1.4em;
    font-weight: bold;
    padding-bottom: 5px;
}

.link-url {
    text-align: right;
    font-size: .8em;
    text-decoration: underline;
    font-style: italic;
}

.link-metadata .link-description {
    border-left: 3px solid var(--font-tertiary-color);
    padding: 10px;
    font-style: italic;
    line-height: 1.2em;
    margin: 10px 0px;
}

span.info-circle {
    margin-right: 5px;
}

.link-url a {
    color: var(--font-color);
}

.link-image img {
    object-fit: cover;
    object-position: top center;
    width: 100%;
    max-height: 350px;
    margin-top: 10px;
}