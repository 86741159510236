.pill {
  color: var(--font-color);
  font-size: var(--font-size-small);
  display: inline-block;
  background-color: var(--gray-secondary);
  padding: 2px 10px;
  border-radius: 10px;
  user-select: none;
  margin: 2px 5px;
}

.pill.unread {
  background-color: var(--gray);
  color:  var(--font-color);
}

.pill:hover {
  cursor: pointer;
}
