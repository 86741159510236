.pfp {
  display: flex;
  align-items: center;
}

.pfp .avatar-wrapper {
  margin-right: 8px;
}

.pfp .avatar {
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.pfp a {
    text-decoration: none;
}

.pfp a:hover {
    text-decoration: underline;
    text-decoration-color: var(--gray-superlight);
}

.pfp .username {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

.pfp .profile-name {
  display: flex;
  flex-direction: column;
}

