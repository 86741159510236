.profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile .banner {
  width: 100%;
  height: 160px;
  object-fit: cover;
  margin-bottom: -60px;
  mask-image: linear-gradient(to bottom, var(--bg-color) 60%, rgba(0,0,0,0));
  -webkit-mask-image: linear-gradient(to bottom, var(--bg-color) 60%, rgba(0,0,0,0));
  z-index: 0;
}

.profile .profile-wrapper {
  margin: 0 16px;
  width: calc(100% - 32px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}


.profile p {
    white-space: pre-wrap;
}

.profile .name h2 {
    margin: 0;
}

.profile .nip05 {
  display: flex;
  margin: 4px 0 12px 0;
}

.profile .nip05 .nick {
  font-weight: normal;
  color: var(--gray-light);
}

.profile .avatar-wrapper {
  z-index: 1;
}

.profile .avatar-wrapper .avatar {
  width: 120px;
  height: 120px;
}

.profile .name {
  display: flex;
  flex-direction: column;
}

.profile .details {
    width: 100%;
    margin-top: 12px;
    background-color: var(--note-bg);
    padding: 12px 16px;
    margin: 0 auto;
    border-radius: 16px;
}

.profile .details p {
    word-break: break-word;
}

.profile .details a {
    color: var(--highlight);
    text-decoration: none;
}

.profile .details a:hover {
    text-decoration: underline;
}

.profile .btn-icon {
    color: var(--font-color);
    padding: 6px;
}

.profile .details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 32px);
}

.tabs > div {
  margin-right: 0;
}

.profile .links {
  margin-top: 4px;
  margin-left: 2px;
}

.profile h3 {
  color: var(--font-secondary-color);
  font-size: 10px;
  letter-spacing: .11em;
  font-weight: 600;
  line-height: 12px;
  text-transform: uppercase;
  margin-left: 16px;
}

.profile .website {
  margin: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile .website a {
  color: var(--font-color);
}

.profile .website a {
    text-decoration: none;
}

.profile .website a:hover {
  text-decoration: underline;
}

.profile .lnurl {
    cursor: pointer;
}

.profile .ln-address {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile .lnurl:hover {
    text-decoration: underline;
}

.profile .lnurl {
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile .link-icon {
  color: var(--highlight);
  margin-right: 8px;
}

.profile .link-icon svg {
  width: 12px;
  height: 12px;
}

.profile .profile-actions {
  position: absolute;
  top: 80px;
  right: 0;
}

@media (min-width: 520px) {
  .profile .profile-actions {
    top: 120px;
  }
}

.profile .profile-actions button:not(:last-child) {
  margin-right: 8px;
}

@media (min-width: 520px) {
  .profile .banner {
    width: 100%;
    max-width: 720px;
    height: 300px;
    margin-bottom: -100px;
  }
  .profile .avatar-wrapper .avatar {
    width: 210px;
    height: 210px;
  }
}

