@import url('https://fonts.googleapis.com/css2?family=Be+Bietnam+Pro:wght@400;600;700&display=swap');

:root {
  --bg-color: #222;
  --font-color: #CCCCCC;
  --font-secondary-color: #FFC045;
  --font-tertiary-color: #666;
  --font-size: 16px;
  --font-size-small: 14px;
  --font-size-tiny: 12px;
  --modal-bg-color: rgba(0, 0, 0, 0.8);
  --note-bg: #2B2B2B;
  --highlight: #FFC045;

  --error: #FF6053;
  --success: #2AD544;

  --gray-superlight: #EEE;
  --gray-light: #999;
  --gray-medium: #666;
  --gray: #444;
  --gray-secondary: #111;
  --gray-tertiary: #555;
  --gray-dark: #2B2B2B;
  --gray-superdark: #171717;

  --gray-gradient: linear-gradient(to bottom right, var(--superlight), var(--highlight), var(--gray-light));
  --snort-gradient: linear-gradient(180deg, #FFC045 0%, #663399 100%);
  --nostrplebs-gradient: linear-gradient(to bottom right, #FFC045, #663399);
  --strike-army-gradient: linear-gradient(to bottom right, #FFC045, #663399);
}

html.light {
  --bg-color: #F1F1F1;
  --font-color: #57534E;
  --font-secondary-color: #FFC045;
  --font-tertiary-color: #F3F3F3;

  --highlight-light: #ffe3ab;
  --highlight: #FFC045;
  --highlight-dark: #f2af2a;
  --modal-bg-color: rgba(0, 0, 0, 0.8);

  --note-bg: #fafafafa;

  --gray: #CCC;
  --gray-secondary: #DDD;
  --gray-tertiary: #EEE;
  --gray-superlight: #333;
  --gray-light: #555;
  --gray-dark: #2B2B2B;
  --gray-superdark: #171717;
}

body {
  margin: 0;
  font-family: 'Be Vietnam Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--font-color);
  font-size: var(--font-size);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page {
  width: 100vw;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 720px) {
  .page {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
  }
}

.card {
  margin-bottom: 12px;
  border-radius: 16px;
  background-color: var(--note-bg);
  padding: 6px 12px;
}

@media (min-width: 720px) {
  .card {
    margin-bottom: 24px;
    padding: 12px 24px;
  }
}

html.light .card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}


.card .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card>.footer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 12px;
}

button {
  cursor: pointer;
  padding: 6px 12px;
  font-weight: bold;
  color: var(--bg-color);
  font-size: var(--font-size);
  background-color: var(--highlight);
  border: none;
  border-radius: 16px;
  outline: none;
}
button:hover {
  background-color: var(--font-color);
  color: var(--bg-color);
}

button.secondary {
  color: var(--font-color);
  background-color: var(--gray-dark);
}
.light button.secondary {
  background-color: var(--gray);
}
button.secondary:hover {
  color: var(--font-color);
  background-color: var(--gray-superdark);
}
.light button.secondary:hover {
  background-color: var(--gray-secondary);
}

.btn {
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--font-color);
  user-select: none;
  background-color: var(--bg-color);
  color: var(--font-color);
  border: 1px solid;
  display: inline-block;
}

.btn-warn {
  border-color: var(--error);
}

.btn-success {
  border-color: var(--success);
}

.btn.active {
  border: 2px solid;
  background-color: var(--gray-secondary);
  color: var(--font-color);
  font-weight: bold;
}

.btn.disabled {
  color: var(--gray-light);
}

.btn:hover {
  background-color: var(--gray);
}

.btn-sm {
  padding: 5px;
}

.btn-rnd {
  border-radius: 100%;
  border-color: var(--gray-superdark);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.light .btn-rnd {
  border-color: var(--gray);
}

textarea {
  font: inherit;
}

input[type="text"], input[type="password"], input[type="number"], textarea, select {
  padding: 10px;
  border-radius: 5px;
  border: 0;
  background-color: var(--gray);
  color: var(--font-color);
}

input[type="checkbox"] {
  width: 24px;
  height: 24px;
}

input:disabled {
  color: var(--gray-medium);
  cursor: not-allowed;
}

textarea:placeholder {
  color: var(--gray-superlight);
}

.flex {
  display: flex;
  align-items: center;
  min-width: 0;
}

.f-center {
  justify-content: center;
}

.f-1 {
  flex: 1;
}

.f-2 {
  flex: 2;
}

.f-grow {
  flex-grow: 1;
  min-width: 0;
}

.f-shrink {
  flex-shrink: 1;
}

.f-ellipsis {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.f-col {
  flex-direction: column;
  align-items: flex-start !important;
}

.f-end {
  justify-content: flex-end;
}

.w-max {
  width: 100%;
  width: stretch;
}

.w-max-w {
  max-width: 100%;
  max-width: stretch;
}

a {
  color: inherit;
  line-height: 1.3em;
}

a.ext {
  word-break: break-all;
  white-space: initial;
}

div.form-group {
  display: flex;
  align-items: center;
}

div.form-group>div {
  padding: 3px 5px;
  word-break: break-word;
}

div.form-group>div:nth-child(1) {
  min-width: 100px;
}

div.form-group>div:nth-child(2) {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

div.form-group>div:nth-child(2) input {
  flex-grow: 1;
}

.modal {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal .modal-content {
  display: flex;
  justify-content: center;
}

.modal .modal-content>div {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--gray);
  margin-top: 5vh;
}

body.scroll-lock {
  overflow: hidden;
  height: 100vh;
}

.pointer { 
  cursor: pointer;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.mr10 {
  margin-right: 10px;
}

.mr5 {
  margin-right: 5px;
}

.mt10 {
  margin-top: 10px;
}

.ml5 {
  margin-left: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mr-auto {
  margin-right: auto;
}

.tabs {
  display: flex;
  align-content: center;
  text-align: center;
  margin: 10px 0;
  overflow-x: auto;
}

.tabs>div {
  margin-right: 10px;
  cursor: pointer;
}

.tabs>div:last-child {
  margin: 0;
}

.tabs .active {
  font-weight: bold;
}

.error {
  color: var(--error);
}

.bg-error {
  background-color: var(--error);
}

.bg-success {
  background-color: var(--success);
}

.tabs {
  padding: 0;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.tab {
  border-bottom: 1px solid var(--gray-secondary);
  font-weight: bold;
  color: var(--font-secondary-color);
  padding: 8px 0;
}

.tab.active {
  border-bottom: 1px solid var(--highlight);
  color: var(--font-color);
}

.tweet {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tweet div {
  width: 100%;
}

.tweet div .twitter-tweet {
  margin: 0 auto;
}

.tweet div .twitter-tweet>iframe {
  max-height: unset;
}

@media(max-width: 720px) {
  div.form-group {
    flex-direction: column;
    align-items: flex-start;
  }
}

.highlight {
  color: var(--highlight);
}

.main-content {
  padding: 0 12px;
}

@media (min-width: 720px) {
  .main-content {
    padding: 0;
  }
}

