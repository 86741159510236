.note {
  min-height: 110px;
}

.note.thread {
  border-bottom: none;
}

.note>.header .reply {
  font-size: var(--font-size-tiny);
  color: var(--font-secondary-color);
}

.note>.header .reply a {
  color: var(--highlight);
}

.note>.header .reply a:hover {
  text-decoration-color: var(--highlight);
}

.note>.header>.info {
  font-size: var(--font-size);
  white-space: nowrap;
  color: var(--font-secondary-color);
}

.note>.body {
  margin-top: 4px;
  padding-left: 56px;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-break: normal;
  overflow-x: hidden;
  overflow-y: visible;
}

.note>.footer {
  padding-left: 46px;
}

.note>.footer .ctx-menu {
  background-color: var(--note-bg);
  color: var(--font-secondary-color);
  border: 1px solid var(--font-secondary-color);
  border-radius: 16px;
  min-width: 0;
}

.note>.footer .ctx-menu li {
  display: grid;
  grid-template-columns: 2rem auto;
}

.ctx-menu .red {
  color: var(--error);
}

.note>.header img:hover, .note>.header .name>.reply:hover, .note .body:hover {
  cursor: pointer;
}

.note>.note-creator {
  margin-top: 12px;
  margin-left: 56px;
}

@media (min-width: 720px) {
  .note>.footer {
    margin-top: 24px;
  }

  .note>.note-creator {
    margin-top: 24px;
  }
}


.thread.note {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.thread.note, .indented .note {
  margin-bottom: 0;
}

.indented .note {
  border-radius: 0;
}

.indented {
  border-left: 3px solid var(--gray-tertiary);
  padding-left: 2px;
}

.note:last-child {
  border-bottom-right-radius: 16px;
  margin-bottom: 24px;
}

.indented .note.active:last-child {
  border-bottom-right-radius: 16px;
  margin-bottom: 24px;
}

.indented>.indented .note:last-child {
  border-bottom-right-radius: 0px;
  margin-bottom: 0;
}

.indented .active {
  background-color: var(--gray-tertiary);
  margin-left: -5px;
  border-left: 3px solid var(--highlight);
  border-radius: 0;
}

.reaction-pill {
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  user-select: none;
  color: var(--font-secondary-color);
  font-feature-settings: "tnum";
}

.reaction-pill .reaction-pill-number {
  margin-left: 8px;
}

.reaction-pill.reacted {
  color: var(--highlight);
}

.reaction-pill:hover {
  cursor: pointer;
}

.trash-icon {
  color: var(--error);
  margin-right: auto;
}

.note.active>.header .reply {
  color: var(--font-tertiary-color);
}

.note.active>.header>.info {
  color: var(--font-tertiary-color);
}

.note.active>.footer>.reaction-pill {
  color: var(--font-tertiary-color);
}

.note.active>.footer>.reaction-pill.reacted {
  color: var(--highlight);
}


.light .indented .active {
  background-color: var(--gray-secondary);
}

.light .note.active>.header .reply {
  color: var(--font-secondary-color);
}

.light .note.active>.header>.info {
  color: var(--font-secondary-color);
}

.light .note.active>.footer>.reaction-pill {
  color: var(--note-bg);
}

.light .note.active>.footer>.reaction-pill.reacted {
  color: var(--highlight);
}

.note-expand .body {
  max-height: 300px;
  overflow-y: hidden;
  mask-image: linear-gradient(to bottom, var(--note-bg) 60%, rgba(0,0,0,0));
  -webkit-mask-image: linear-gradient(to bottom, var(--note-bg) 60%, rgba(0,0,0,0));
}