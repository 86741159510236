.nts {
    display: flex;
    align-items: center;
}

.note-to-self {
  margin-left: 5px;
  margin-top: 3px;
}

.nts .avatar-wrapper {
  margin-right: 8px;
}

.nts .avatar {
    border-width: 1px;
    width: 40px;
    height: 40px;
}
.nts .avatar.clickable {
  cursor: pointer;
}

.nts a {
    text-decoration: none;
}

.nts a:hover {
    text-decoration: underline;
    text-decoration-color: var(--gray-superlight);
}

.nts .name {
  margin-top: -.2em;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.nts .nip05 {
  margin: 0;
  margin-top: -.2em;
}
