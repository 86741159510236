.note-creator {
    margin-bottom: 10px;
    background-color: var(--note-bg);
    border: 1px solid var(--gray);
    border-radius: 10px;
    padding: 6px;
}

.note-reply {
    margin: 10px;
}

.note-creator .textarea {
    outline: none;
    resize: none;
    background-color: var(--note-bg);
    border-radius: 10px 10px 0 0;
    max-width: stretch;
    min-width: stretch;
}

.note-creator-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.note-creator .attachment {
    cursor: pointer;
    margin-left: auto;
}

.note-creator-actions button:not(:last-child) {
    margin-right: 4px;
}

.note-creator .attachment .error {
    font-weight: normal;
    margin-right: 5px;
    font-size: 14px;
}

.note-creator .btn {
    border-radius: 20px;
    font-weight: bold;
    background-color: var(--bg-color);
    color: var(--font-color);
    font-size: var(--font-size);
}

.note-create-button {
  width: 48px;
  height: 48px;
  background-color: var(--highlight);
  border: none;
  border-radius: 100%;
  position: fixed;
  bottom: 50px;
  right: 16px;
}

@media (min-width: 520px) {
  .note-create-button {
    right: 10vw;
  }
}

@media (min-width: 1020px) {
  .note-create-button {
    right: 25vw;
  }
}
