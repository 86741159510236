.profile-preview {
    display: flex;
    padding: 5px 0;
    align-items: center;
    min-height: 40px;
}

.profile-preview .pfp {
    flex-grow: 1;
    min-width: 200px;
}

.profile-preview .about {
    font-size: small;
    color: var(--gray-light);
}