.nip05 {
    justify-content: flex-start;
    align-items: center;
    margin: .2em;
}

.nip05.failed {
    text-decoration: line-through;
}

.nip05 .nick {
    color: var(--font-secondary-color);
    font-weight: bold;
    margin-right: .2em;
}

.nip05 .domain {
    color: var(--gray-superlight);
    font-weight: bold;
    margin-left: .2em;
}

.nip05 .text-gradient {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-color: var(--gray-superlight);
}

.nip05 .domain[data-domain="snort.social"] {
    background-image: var(--snort-gradient);
}

.nip05 .domain[data-domain="nostrplebs.com"] {
    background-image: var(--nostrplebs-gradient);
}

.nip05 .domain[data-domain="nostrpurple.com"] {
    background-image: var(--nostrplebs-gradient);
}

.nip05 .domain[data-domain="nostr.fan"] {
    background-image: var(--nostrplebs-gradient);
}

.nip05 .domain[data-domain="nostrich.zone"] {
    background-image: var(--nostrplebs-gradient);
}

.nip05 .domain[data-domain="nostriches.net"] {
    background-image: var(--nostrplebs-gradient);
}

.nip05 .domain[data-domain="strike.army"] {
    background-image: var(--strike-army-gradient);
}

.nip05 .domain[data-domain="nostr.13x.sh"] {
    background-image: var(--nostrplebs-gradient);
}

.nip05 .badge {
     margin: .1em .2em;
}
