.lnurl-tip {
    text-align: center;
}

.lnurl-tip .btn {
    background-color: inherit;
    width: 210px;
    margin: 0 0 10px 0;
}

.lnurl-tip .btn:hover {
    background-color: var(--gray);
}

.sat-amount {
  display: inline-block;
  background-color: var(--gray-secondary);
  color: var(--font-color);
  padding: 2px 10px;
  border-radius: 10px;
  user-select: none;
  margin: 2px 5px;
}

.sat-amount:hover {
  cursor: pointer;
}

.sat-amount.active {
  font-weight: bold;
  color: var(--note-bg);
  background-color: var(--font-color);
}

.lnurl-tip .invoice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.lnurl-tip .invoice .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.lnurl-tip .invoice .actions .copy-action {
    margin: 10px auto;
}

.lnurl-tip .invoice .actions .pay-actions {
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
