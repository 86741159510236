.note-invoice {
    border: 1px solid var(--font-secondary-color);
    border-radius: 16px;
    padding: 12px;
    margin: 10px auto;
}

.note-invoice h2, .note-invoice h4, .note-invoice p {
    margin: 10px 0;
}

.note-invoice small {
    color: var(--font-secondary-color);
}
